import { addMinutes, addSeconds, getUnixTime } from 'date-fns';

export function getDefaultVisibleRange(resolution: any) {
  switch (resolution) {
    case '5': {
      return {
        from: getUnixTime(addMinutes(new Date(), -600)),
        to: getUnixTime(addMinutes(new Date(), 5)),
      };
    }
    case '1': {
      return {
        from: getUnixTime(addMinutes(new Date(), -120)),
        to: getUnixTime(addMinutes(new Date(), 1)),
      };
    }
    case '30S': {
      return {
        from: getUnixTime(addMinutes(new Date(), -60)),
        to: getUnixTime(addSeconds(new Date(), 30)),
      };
    }
    case '15S': {
      return {
        from: getUnixTime(addMinutes(new Date(), -30)),
        to: getUnixTime(addSeconds(new Date(), 15)),
      };
    }
    case '5S': {
      return {
        from: getUnixTime(addMinutes(new Date(), -10)),
        to: getUnixTime(addSeconds(new Date(), 10)),
      };
    }
    case '1T': {
      return {
        from: getUnixTime(addMinutes(new Date(), -3)),
        to: getUnixTime(addSeconds(new Date(), 1)),
      };
    }
  }
}
