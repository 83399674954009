import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Collapse } from 'antd';
import cn from 'classnames';

import PriceWillGo from '../../../components/PriceWillGo/PriceWillGo';
import { PLAYER_ID } from '../../../constants/constatnts';
import { getAutoBetErrorForClose, getAutoBetErrorForTake } from '../../../helpers/autoBetErrors';
import { calculateBustPrice } from '../../../helpers/autoBetsFormulas';
import { useShowMessage } from '../../../helpers/messages';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAppDispatch } from '../../../store';
import { openBet } from '../../../store/bet/asyncThunks/slice';
import { changeBetTypeAction, changePriceWillGoAction } from '../../../store/bet/slices';
import { setBetWidgetDrawerVisible } from '../../../store/ui/slices';
import { BetType, Prediction } from '../../../types/entities';

import AutoBet from './AutoBet/AutoBet';
import BetWidgetTabs from './BetWidgetTabs/BetWidgetTabs';
import FeeOptions from './FeeOptions/FeeOptions';
import PlaceBet from './PlaceBet/PlaceBet';
import SliderComponent from './SliderComponent/SliderComponent';
import Wager from './Wager/Wager';

import styles from './BetWidget.module.scss';

type BetWidgetProps = {
  widgetOpened?: boolean;
  openWidget?: () => void;
};

export default function BetWidget({ widgetOpened }: BetWidgetProps) {
  const playerId = PLAYER_ID;
  const { isMobile } = useWindowSize();
  const dispatch = useAppDispatch();
  const { multiplierValue, priceWillGo, betValue, takePrice, takeProfit, closePrice, closeLoss, betType } =
    useTypedSelector((state) => state.bet);
  const { currentAsset } = useTypedSelector((state) => state.assets);

  const entryPrice = currentAsset?.lastPrice || 0;

  const isPriceUp = priceWillGo === Prediction.UP;

  const bustPrice = calculateBustPrice(entryPrice, multiplierValue, isPriceUp);

  const [height, setHeight] = useState(0);
  const [isSubmitPressed, setIsSubmitPressed] = useState<boolean>(false);
  const blockRef: any = useRef();
  const { showSuccess, contextHolder, showError } = useShowMessage();

  const { isErrorTakeCurrentPrice, errorTakeCurrentPriceText } = getAutoBetErrorForTake(
    entryPrice,
    priceWillGo,
    takePrice,
  );
  const { isErrorCloseCurrentPrice, isErrorCloseBustPrice, isErrorCloseLoss, errorCloseText } = getAutoBetErrorForClose(
    entryPrice,
    priceWillGo,
    closePrice.toString(),
    closeLoss,
    bustPrice,
  );

  const opened = useMemo(() => !isMobile || widgetOpened, [isMobile, widgetOpened]);

  const handleChangeActiveTab = (key: string) => {
    dispatch(changeBetTypeAction(key));
    if (key === 'manual') {
      setIsSubmitPressed(false);
    }
  };

  const handleClick = async () => {
    if (!opened) {
      return dispatch(setBetWidgetDrawerVisible(true));
    }
    if (betType === BetType.AUTO) {
      setIsSubmitPressed(true);
    }

    const isForbidSubmit = betType === BetType.AUTO && ((!takePrice && !takeProfit) || (!closePrice && !closeLoss));
    if (isForbidSubmit) {
      return;
    }
    if (currentAsset) {
      const baseBetRequest = {
        openPrice: entryPrice,
        bustPrice: Math.round(bustPrice * 100) / 100,
        amount: betValue,
        multiplier: multiplierValue,
        prediction: priceWillGo,
        isPublic: true,
        status: 'ACTIVE',
        commissionType: 'PNL',
        playerId: playerId,
        assetId: currentAsset.id,
      };
      const autoBetFields = {
        takeProfitPrice: parseFloat(takePrice.replace(/,/g, '')),
        stopLossPrice: parseFloat(closePrice.replace(/,/g, '')),
      };

      const betRequestPayload = betType === BetType.MANUAL ? baseBetRequest : { ...baseBetRequest, ...autoBetFields };

      const response = await dispatch(openBet(betRequestPayload));

      if (response?.payload) {
        if (response.payload.status === 400 || response.payload.status === 500) {
          console.log(response.payload.data);
          if (Array.isArray(response.payload.data?.fieldErrors)) {
            response.payload.data.fieldErrors.forEach((err: any) => {
              showError(err?.message || 'Something went wrong, try again');
            });
          } else {
            showError(response.payload.data?.detail || 'Something went wrong, try again');
          }
        } else if (response.payload?.status && response.payload.status > 300) {
          showError('Something went wrong, try again');
          setIsSubmitPressed(false);
        } else {
          showSuccess('Bet successfully created');
          setIsSubmitPressed(false);
        }
      } else {
        showError('Something went wrong, try again');
        setIsSubmitPressed(false);
      }
    }
  };

  useEffect(() => {
    if (blockRef.current) {
      const el = blockRef.current;
      const h = el.offsetHeight;
      if (h > 0) {
        setHeight(h);
      }
    }
  }, [opened]);
  useEffect(() => {
    if (opened && height && blockRef.current) {
      const el = blockRef.current;
      el.style.height = `${height}px`;
      setTimeout(() => {
        el.style.height = ``;
      }, 300);
    } else if (!opened) {
      const el = blockRef.current;
      const h = el.offsetHeight;
      if (h > 0) {
        setHeight(h);
        el.style.height = `${h}px`;
      }
      setTimeout(() => (el.style.height = `0px`), 10);
    }
  }, [opened]);

  const handleChangePriceWillGo = (value: string) => {
    dispatch(changePriceWillGoAction(value));
  };

  return (
    <div className={cn(styles.betWidget, isMobile && !opened && styles.betWidget_closed)}>
      {contextHolder}
      <div ref={blockRef} className={opened ? styles.opened : height ? styles.closed : styles.compute}>
        <BetWidgetTabs activeTab={betType} onChangeActiveTab={handleChangeActiveTab} />
        <PriceWillGo priceWillGo={priceWillGo} onChange={handleChangePriceWillGo} />
        <Wager betValue={betValue} />
        <SliderComponent bustPrice={bustPrice} multiplierValue={multiplierValue} />
        <Collapse
          activeKey={betType}
          ghost
          items={[
            {
              key: BetType.AUTO,
              label: null,
              children: (
                <AutoBet
                  priceWillGo={priceWillGo}
                  takePrice={takePrice}
                  takeProfit={takeProfit}
                  closePrice={closePrice}
                  closeLoss={closeLoss}
                  isErrorTakeCurrentPrice={isErrorTakeCurrentPrice}
                  errorTakeCurrentPriceText={errorTakeCurrentPriceText}
                  isErrorCloseLoss={isErrorCloseLoss}
                  isErrorCloseCurrentPrice={isErrorCloseCurrentPrice}
                  isErrorCloseBustPrice={isErrorCloseBustPrice}
                  errorCloseText={errorCloseText}
                  entryPrice={entryPrice}
                />
              ),
              showArrow: false,
            },
          ]}
        />
        {isMobile && <FeeOptions />}
      </div>

      <PlaceBet
        betType={betType}
        bustPrice={bustPrice}
        priceWillGo={priceWillGo}
        betValue={betValue}
        multiplierValue={multiplierValue}
        takePrice={takePrice}
        closePrice={closePrice}
        widgetOpened={opened}
        isErrorTakeCurrentPrice={isErrorTakeCurrentPrice}
        isErrorCloseCurrentPrice={isErrorCloseCurrentPrice}
        isErrorCloseBustPrice={isErrorCloseBustPrice}
        isErrorCloseLoss={isErrorCloseLoss}
        takeProfit={takeProfit}
        closeLoss={closeLoss}
        isSubmitPressed={isSubmitPressed}
        onClick={handleClick}
      />
      {!isMobile && <FeeOptions />}
    </div>
  );
}
