import React, { useMemo } from 'react';
import Icon from '@ant-design/icons';

import { InfoIcon } from '../../../assets/icons';
import { ActiveBet } from '../../../types/entities';

import styles from './ActiveBetFundingCell.module.scss';

type Props = {
  bet: ActiveBet;
};

export default function ActiveBetFundingCell({ bet }: Props) {
  const fee = useMemo(() => {
    if (bet.fundingFeePercent && !Number.isNaN(bet.fundingFeePercent)) {
      return (bet.amount * bet.fundingFeePercent) / 100;
    }
    return '-';
  }, [bet]);

  return (
    <span className={styles.wrapper}>
      {fee}
      <Icon component={InfoIcon} className={styles.infoIcon} />
    </span>
  );
}
