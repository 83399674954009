import { createSlice } from '@reduxjs/toolkit';

import { PLAYER_ID } from '../../constants/constatnts';

import { UserSliceState } from './types';

const initialState: UserSliceState = {
  token:
    'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImV4cCI6MTczNTcyMTMwOSwiYXV0aCI6IlJPTEVfQURNSU4gUk9MRV9VU0VSIiwiaWF0IjoxNzMzMTI5MzA5fQ.iDPNWIwVVSkauT2CwiM6b4Wtc8nKXVCSLUzszV98nWzkhJGZ75ymimOC1B3PEEQ8Ks-UXfVs4msU6jC7cvK_qA',
  playerId: PLAYER_ID,
  player: {
    id: PLAYER_ID,
    userName: 'Dan Redford',
    photoUrl: 'https://picsum.photos/50',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPlayer: (state, action) => {
      state.player = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setToken, setPlayer } = userSlice.actions;

export default userSlice.reducer;
