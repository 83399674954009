import React, { useCallback, useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';
import { ConfigProvider, Table } from 'antd';

import ClosedBetActions from '../../components/ClosedBetActionButtons/ClosedBetActions';
import EmptyState from '../../components/EmptyState/EmptyState';
import FormattedAmount from '../../components/FormattedAmount/FormattedAmount';
import TableLoader from '../../components/Loader/TableLoader';
import BetPairCell from '../../components/TableCells/BetPairCell/BetPairCell';
import BetStatusCell from '../../components/TableCells/BetStatusCell/BetStatusCell';
import PNLCell from '../../components/TableCells/PNLCell/PNLCell';
import ROICell from '../../components/TableCells/ROICell/ROICell';
import { PLAYER_ID } from '../../constants/constatnts';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { getClosedBets, getMoreClosedBets } from '../../store/betList/asyncThunks/slice';

import styles from '../../styles/table.module.scss';

export default function ClosedBets() {
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowSize();
  const { player } = useTypedSelector((state) => state.user);
  const {
    closedBets,
    loading,
    closedBetsPageParams: { last, totalPages, number },
  } = useTypedSelector((state) => state.betList);

  useEffect(() => {
    if (player?.id) {
      dispatch(getClosedBets(player.id));
    }
  }, [dispatch, player]);

  const bottomRef = useRef(null);
  const intersection = useIntersection(bottomRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  });

  const fetchMoreData = useCallback(() => {
    console.log({ last, number, totalPages });
    if (!last) {
      dispatch(getMoreClosedBets({ playerId: Number(player?.id), page: number + 1 }));
    }
  }, [last, number, totalPages, player, dispatch]);

  useEffect(() => {
    if (intersection?.isIntersecting) {
      console.log('Fetch more data');
      fetchMoreData();
    }
  }, [intersection?.isIntersecting]);

  const columns = [
    {
      title: 'Bet',
      dataIndex: 'playerCurrency',
      width: 150,
      render: (_: any, record: any) => (
        <BetPairCell coin={record.assetName} direction={record.prediction} image={record.assetIcon} />
      ),
    },
    {
      title: 'Wager',
      dataIndex: 'amount',
      key: 'amount',
      width: 60,
      render: (amount: number) => (
        <span className={styles.highlighted}>
          <FormattedAmount amount={amount} />
        </span>
      ),
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      width: 60,
      render: (text: string) => <span className={styles.highlighted}>{`x${text}`}</span>,
    },
    {
      title: 'Entry price',
      dataIndex: 'openPrice',
      key: 'openPrice',
      width: 110,
      render: (price: number) => <FormattedAmount amount={price} />,
    },
    {
      title: 'Bust price',
      dataIndex: 'bustPrice',
      key: 'bustPrice',
      width: 110,
      render: (price: number) => <FormattedAmount amount={price} />,
    },
    {
      title: 'Exit price',
      dataIndex: 'closePrice',
      key: 'closePrice',
      width: 110,
      render: (price: number) => <FormattedAmount amount={price} />,
    },
    {
      title: 'PNL',
      dataIndex: 'profit',
      key: 'profit',
      width: 80,
      render: (value: number) => <PNLCell value={value} />,
    },
    {
      title: 'ROI',
      dataIndex: 'roi',
      key: 'roi',
      width: 80,
      render: (value: number) => <ROICell value={value} />,
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      width: 110,
      render: (value: any) => <BetStatusCell status={value} />,
    },
    {
      title: '',
      render: (_: any, bet: any) => <ClosedBetActions bet={bet} />,
      fixed: isMobile ? ('right' as any) : undefined,
      width: 80,
    },
  ];

  const renderEmptyState = useCallback(
    () => (loading ? <TableLoader isLoading={true} /> : <EmptyState description={['No closed bets yet']} smallImg />),
    [loading],
  );

  return (
    <ConfigProvider renderEmpty={renderEmptyState}>
      <div className={styles.tableWrapper}>
        <Table
          rowClassName={styles.row}
          dataSource={closedBets}
          columns={columns}
          pagination={false}
          rowKey='id'
          className={styles.table}
          scroll={{ x: isMobile ? 500 : 955 }}
        />
        <TableLoader isLoading={!!closedBets?.length && loading} isInner={false} />
        {!!closedBets.length && <div className={styles.bottom} ref={bottomRef} />}
      </div>
    </ConfigProvider>
  );
}
