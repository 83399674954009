import React, { useCallback, useState } from 'react';
import { ConfigProvider, Table } from 'antd';

import EmptyState from '../../components/EmptyState/EmptyState';
import FormattedAmount from '../../components/FormattedAmount/FormattedAmount';
import TableLoader from '../../components/Loader/TableLoader';
import ActiveBetActions from '../../components/TableCells/ActiveBetActionButtons/ActiveBetActions';
import ActiveBetCashOutButton from '../../components/TableCells/ActiveBetActionButtons/ActiveBetCashOutButton';
import ActiveBetFundingCell from '../../components/TableCells/ActiveBetFundingCell/ActiveBetFundingCell';
import BetPairCell from '../../components/TableCells/BetPairCell/BetPairCell';
import PNLCell from '../../components/TableCells/PNLCell/PNLCell';
import { useShowMessage } from '../../helpers/messages';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { ActiveBet } from '../../types/entities';

import styles from '../../styles/table.module.scss';

export default function ActiveBets() {
  const { showCashOutMessage, contextHolder } = useShowMessage();
  const [pendingBets, setPendingBets] = useState<string[]>([]);
  const { isMobile } = useWindowSize();

  const { activeBets, loading } = useTypedSelector((state) => state.betList);

  const onCashOut = useCallback(
    (bet: any) => {
      setPendingBets((arr: string[]) => [...arr, bet?.id]);
      setTimeout(() => {
        showCashOutMessage(bet); //TODO: remove show message
        setPendingBets((arr) => arr.filter((el) => el !== bet?.id));
      }, 60000);
    },
    [showCashOutMessage],
  );

  const commonColumns = [
    {
      title: 'Bet',
      key: 'playerCurrency',
      width: 150,
      render: (_: any, record: ActiveBet) => (
        <BetPairCell coin={record.assetName} direction={record.prediction} image={record.assetIcon} />
      ),
      className: styles.header,
    },
    {
      title: 'Wager',
      dataIndex: 'amount',
      key: 'amount',
      width: 80,
      render: (amount: number) => (
        <span className={styles.highlighted}>
          <FormattedAmount amount={amount} />
        </span>
      ),
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      width: 80,
      render: (text: string) => <span className={styles.highlighted}>{`x${text}`}</span>,
    },
    {
      title: 'Entry price',
      dataIndex: 'openPrice',
      key: 'openPrice',
      width: 100,
      render: (price: number) => <FormattedAmount amount={price} />,
    },
    {
      title: 'Bust price',
      dataIndex: 'bustPrice',
      key: 'bust',
      width: 100,
      render: (price: number) => <FormattedAmount amount={price} />,
    },
    {
      title: 'Funding/hour',
      key: 'fund',
      width: 110,
      render: (_: any, record: ActiveBet) => <ActiveBetFundingCell bet={record} />,
    },
    {
      title: 'PNL',
      dataIndex: 'profit',
      key: 'profit',
      render: (value: number) => <PNLCell value={value} />,
      width: 100,
    },
  ];

  const columns = isMobile
    ? [
        ...commonColumns,
        {
          title: '',
          render: (_: any, bet: any) => <ActiveBetActions bet={bet} />,
          width: 72,
        },
        {
          title: '',
          render: (_: any, bet: any) => (
            <ActiveBetCashOutButton onCashOut={() => onCashOut(bet)} loading={pendingBets.includes(bet?.id)} />
          ),
          width: 113,
          fixed: 'right' as any,
        },
      ]
    : [
        ...commonColumns,
        {
          title: '',
          render: (_: any, bet: any) => (
            <ActiveBetCashOutButton onCashOut={() => onCashOut(bet)} loading={pendingBets.includes(bet?.id)} />
          ),
          width: 109,
        },
        {
          title: '',
          render: (_: any, bet: ActiveBet) => <ActiveBetActions bet={bet} />,
          width: 72,
        },
      ];

  const renderEmptyState = useCallback(
    () => (loading ? <TableLoader isLoading={true} /> : <EmptyState description={['No active bets']} smallImg />),
    [loading],
  );

  return (
    <ConfigProvider renderEmpty={renderEmptyState}>
      {contextHolder}
      <div className={styles.tableWrapper}>
        <Table
          rowClassName={styles.row}
          dataSource={activeBets}
          columns={columns}
          pagination={false}
          rowKey='id'
          className={styles.table}
          scroll={{ x: isMobile ? 500 : 955 }}
        />
      </div>
    </ConfigProvider>
  );
}
