import React, { useCallback, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { Splitter } from 'antd';

import { API } from '../../api/uri';
import { QuestionMarkIcon } from '../../assets/icons';
import BetWidgetDrawer from '../../components/BetWidgetDriver/BetWidgetDrawer';
import ChartWidget from '../../components/ChartWidget/ChartWidget';
import TableTabs from '../../components/TabHeader/TableTabs';
import { FatalError, RetriableError } from '../../helpers/EventSourceErrors';
import { useShowMessage } from '../../helpers/messages';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { setActiveBets, setNewClosedBet } from '../../store/betList/slices';
import { setBetWidgetDrawerVisible, setMenuDrawerVisible } from '../../store/ui/slices';

import BetWidget from './BetWidget/BetWidget';

import styles from './BetPage.module.scss';

export default function MainPage() {
  const { isMobile } = useWindowSize();
  const activeBetsAbortControllerRef = useRef(new AbortController());
  const closedBetsAbortControllerRef = useRef(new AbortController());
  const dispatch = useAppDispatch();
  const { playerId, token } = useTypedSelector((state) => state.user);
  const { showCashOutMessage, contextHolder } = useShowMessage();

  const showMenu = useCallback(() => {
    dispatch(setBetWidgetDrawerVisible(false));
    dispatch(setMenuDrawerVisible(true));
  }, [dispatch]);

  const subscribeOnActiveBets = useCallback(async () => {
    console.log('Start get bets');
    activeBetsAbortControllerRef.current = new AbortController();
    await fetchEventSource(`${API.PLAYER_BETS_LIST}/${playerId}/active`, {
      signal: activeBetsAbortControllerRef.current.signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          return;
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(message) {
        try {
          const bets = JSON.parse(message.data);
          if (Array.isArray(bets)) {
            dispatch(setActiveBets(bets));
          }
        } catch (e) {
          console.log('error parse message');
        }
      },
      onclose() {
        throw new RetriableError();
      },
      onerror(err) {
        if (err instanceof FatalError) {
          throw err;
        } else {
          console.log('Try to reconnect');
          // do nothing to automatically retry. You can also return a specific retry interval here.
        }
      },
    });
  }, [playerId, token]);

  const subscribeOnClosedBets = useCallback(async () => {
    console.log('Start get closed bets');
    closedBetsAbortControllerRef.current = new AbortController();
    await fetchEventSource(`${API.PLAYER_BETS_LIST}/${playerId}/closed/bets`, {
      signal: activeBetsAbortControllerRef.current.signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          return;
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(message) {
        try {
          const bets = JSON.parse(message.data);
          if (Array.isArray(bets)) {
            bets.forEach((item) => {
              if (item.betDetails?.id) {
                showCashOutMessage(item.betDetails);
              }
            });
            dispatch(setNewClosedBet(bets.map((i) => i.betDetails)));
          }
        } catch (e) {
          console.log('error parse message');
        }
      },
      onclose() {
        throw new RetriableError();
      },
      onerror(err) {
        if (err instanceof FatalError) {
          throw err;
        } else {
          console.log('Try to reconnect');
        }
      },
    });
  }, [playerId, token]);

  useEffectOnce(() => {
    if (playerId && token) {
      subscribeOnActiveBets();
      subscribeOnClosedBets();
    }
    return () => {
      console.log('abort called');
      if (activeBetsAbortControllerRef.current) {
        activeBetsAbortControllerRef.current.abort();
      }
      if (closedBetsAbortControllerRef.current) {
        closedBetsAbortControllerRef.current.abort();
      }
    };
  });

  if (isMobile) {
    return (
      <>
        {contextHolder}
        <Splitter layout={'vertical'} className={styles.splitter}>
          <Splitter.Panel min={200}>
            <div className={styles.chartContainerMobile}>
              <ChartWidget />
              <div className={styles.menuButton} onClick={showMenu}>
                <QuestionMarkIcon />
              </div>
            </div>
          </Splitter.Panel>
          <Splitter.Panel min={10}>
            <div className={styles.tableContainer}>
              <TableTabs />
            </div>
          </Splitter.Panel>
        </Splitter>
        <BetWidgetDrawer />
      </>
    );
  }

  return (
    <div className={styles.pageContainer}>
      {contextHolder}
      <div className={styles.betContainer}>
        <div className={styles.chartContainer}>
          <ChartWidget />
        </div>
        <BetWidget />
      </div>

      <div className={styles.tableContainer}>
        <TableTabs />
      </div>
    </div>
  );
}
